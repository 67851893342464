import React from "react";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../images/Grow.png";

const Principles = ({ variant, title, content }) => {
  return (
    <Container fluid style={containerStyle}>
      <Jumbotron
        fluid
        style={jumbotronLightStyle}
        className="d-flex align-items-center"
      >
        <Container>
          <Row>
            <Col md={8}>
              <h1 className="secondaryColor">
                {title}
              </h1>
              <br />
              <ul>
                {renderContent(content)}
              </ul>
            </Col>
            <Col md={4} style={withBackgroundImage}></Col>
          </Row>
        </Container>
      </Jumbotron>
    </Container>
  );
};

const renderContent = (content) => {
  return content.map((line) => {
    return <li style={textStyle}>{line}</li>;
  });
};

const baseJumbotronStyle = {
  backgroundSize: "800px",
  backgroundRepeat: "no-repeat",
  fontSize: "1.8rem",
  marginBottom: 0,
  textAlign: "left",
  backgroundColor: "#FFF",
};

const jumbotronLightStyle = {
  ...baseJumbotronStyle,
};

const withBackgroundImage = {
  ...baseJumbotronStyle,
  backgroundImage: `url("${backgroundImage}")`,
  backgroundPosition: "50% 30%",
};


const textStyle = {
  fontWeight: 400,
  color: "#3f424a",
  padding: "10px 0"
};

const containerStyle = {
  padding: 0,
};

export default Principles;
