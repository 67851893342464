import React from "react";
import Letter from "../components/Letter";
import Actions from "../components/Actions";
import ImageBanner from "../components/ImageBanner";
import Principles from "../components/Principles";

// TODO: Move to locale
const IMAGE_TITLE =
  "¿Cómo preparamos a nuestras hijas e hijos para una realidad de cambio constante?";
const IMAGE_CONTENT = `Vivimos en "la era de la aceleración", los conocimientos adquiridos bajo esquemas de educación convencionales ya no son suficientes a largo plazo, la capacidad de aprender rápido y saber cómo aprender es crucial.`;
const PRINCIPLES_TITLE = "Nuestros principios"
const PRINCIPLES = [
  "Mentalidad de crecimiento: todos podemos desarrollar nuevas habilidades",
  'Desarrollando "ultra-learners": el conocimiento no es la meta, aprender es el camino a seguir creciendo.', 
  "Bloques de aprendizaje: pensar de manera iterativa y encontrar el contexto, las metas y los mecanismos adecuados para desarrollar la mente y el cuerpo.",  
  "Auto-dirección: autonomía y curiosidad como aspectos clave para el crecimiento y aprendizaje. ",
  "Explora, Experimenta y Aprende: cada persona es distinta y tener acceso a múltiples opciones y experiencias permitirá que cada individuo pueda maximizar su potencial." 
]

const Home = () => {
  return (
    <>
      <ImageBanner variant="light" title={IMAGE_TITLE} content={IMAGE_CONTENT} />
      {/* <Question /> */}
      <Letter />
      <Actions />
      <Principles title={PRINCIPLES_TITLE} content={PRINCIPLES} />
    </>
  );
};

export default Home;
