import React, { useState, useEffect } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/coloristic.css';
import './styles/global.css';

import Home from './pages/Home'
import Container from './components/Container'

const Router = () => {
  const [current, setCurrent] = useState('home')
  useEffect(() => {
    setRoute()
    window.addEventListener('hashchange', setRoute)
    return () =>  window.removeEventListener('hashchange', setRoute)
  }, [])
  function setRoute() {
    const location = window.location.href.split('/')
    const pathname = location[location.length-1]
    // TODO send path to app container
  }
  return (
    <HashRouter>
      <Container>
        <Switch>
          <Route exact path="/" component={Home}/>
        </Switch>
      </Container>
    </HashRouter>
  )
}

export default Router