import React from "react";
import { Jumbotron, Container, Row, Col } from "react-bootstrap";
import backgroundImage from "../images/universe.png";

const ImageBanner = ({ variant, title, content }) => {
  return (
    <Container fluid style={containerStyle}>
      <Jumbotron
        fluid
        style={jumbotronLightStyle}
        className="d-flex align-items-center"
      >
        <Container>
          <Row>
            <Col md={7}>
            <h1 style={baseTextStyle} className="secondaryColor">{title}</h1>
          <br />
          <p style={textStyle}>{content}</p>
            </Col>
            <Col md={5} style={withBackgroundImage}></Col>
          </Row>
        </Container>
      </Jumbotron>
    </Container>
  );
};

const baseJumbotronStyle = {
  backgroundSize: "110%",
  backgroundRepeat: "no-repeat",
  fontSize: "1.8rem",
  marginBottom: 0,
  textAlign: "left",
  backgroundColor: "#F8F8F8"
};

const jumbotronLightStyle = {
  ...baseJumbotronStyle,
};

const withBackgroundImage = {
  ...baseJumbotronStyle,
  backgroundImage:
    `url("${backgroundImage}")`,
  backgroundPosition: "150% 30%",
}

const baseTextStyle = {
  fontFamily: "Lato, Open Sans",
};

const textStyle = {
  ...baseTextStyle,
  fontWeight: 400,
  color: "#3f424a",
};

const containerStyle = {
  padding: 0
};

export default ImageBanner;
