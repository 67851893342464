import React from "react";
import { Nav, Navbar, Button, Container } from "react-bootstrap";

const Header = ({ noLinks }) => {
  const renderLinks = () => {
    if (noLinks) {
      return null;
    }
    return (
      <>
      <Nav fill style={navBarStyle}>
        <Nav.Link href="#home" style={navLinkStyle}>
          Home
        </Nav.Link>
        <Nav.Link href="#features" style={navLinkStyle}>
          Classes
        </Nav.Link>
        <Nav.Link href="#pricing" style={navLinkStyle}>
          Programs
        </Nav.Link>
        <Nav.Link href="#pricing" style={navLinkStyle}>
          Schools
        </Nav.Link>
      </Nav>
      <Button variant="dark" className="btn-dark">
        Sign up
      </Button>
      </>
    );
  };

  return (
    <>
      <Navbar
        collapseOnSelect
        expand="md"
        fixed="top"
        variant="light"
        className="navbar-default navbar navbar-fixed-top top-nav"
      >
        <Container>
          <Navbar.Brand href="#home" className="navbar-brand" style={logoStyle}>
            Kidux
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" hidden={true} />
          <Navbar.Collapse id="responsive-navbar-nav" style={linkContainer}>
            {renderLinks()}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

// Styles
const linkContainer = {
  justifyContent: "flex-end",
};

const logoStyle = {
  fontFamily: "Rubik, sans-serif",
  fontWeight: "700",
  color: "#4ED0D1"
};

const navLinkStyle = {
  fontWeight: "700",
  color: "#393f4a",
  textTransform: "uppercase",
  fontSize: "15px",
};

const navBarStyle = {
  boxShadow: "0 0 3rem rgba(0, 0, 0, 0.1)"
}

export default Header;
