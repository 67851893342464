import React from "react";
import { Container } from "react-bootstrap";

const footerLink = ({ label }) => {
  return (
    <li>
      <a className="text-muted" href="#">
        {label}
      </a>
    </li>
  );
};

const Footer = ({ noLinks }) => {
  const renderLinks = () => {
    if (noLinks) return null;
    return (
      <div className="row">
        <div className="col-md-3">
          <h5></h5>
          <ul className="list-unstyled text-small"></ul>
        </div>
        <div className="col-md-3">
          <h5 style={h5Style}>Our Offerings</h5>
          <ul className="list-unstyled text-small">
            <footerLink label="Cool Stuff" />
            <footerLink label="Random feature" />
            <footerLink label="Team feature" />
            <footerLink label="Stuff for developers" />
            <footerLink label="Another one" />
            <footerLink label="Last time" />
          </ul>
        </div>
        <div className="col-md-3">
          <h5 style={h5Style}>Learning</h5>
          <ul className="list-unstyled text-small">
          <footerLink label="Resource" />
          <footerLink label="Resource name" />
          <footerLink label="Another resource" />
          <footerLink label="Final resource" />
          </ul>
        </div>
        <div className="col-md-3">
          <h5 style={h5Style}>About</h5>
          <ul className="list-unstyled text-small">
          <footerLink label="Team" />
          <footerLink label="Locations" />
          <footerLink label="Privacy" />
          <footerLink label="Terms" />
          </ul>
        </div>
      </div>
    );
  };
  return (
    <Container style={footerStyle}>
      <div className="row">
        <div className="col-md-12">
          <small className="d-block mb-3">© 2020 Kidux.</small>
        </div>
      </div>
    </Container>
  );
};

const footerStyle = {
  backgroundColor: "transparent",
};

const h5Style = {
  color: "white",
};

export default Footer;
