import React from "react";
import { Card, Container, Button } from "react-bootstrap";

const Letter = () => {
  return (
    <Container style={style}>
      <Card style={cardStyle}>
        <Card.Body>
          <Card.Text style={cardTextStyle}>
            <p>
              A nuestros amigos padres de familia:
            </p>
            <p>
            Somos dos papás, apasionados por la tecnología (geeks de corazón), curiosos por naturaleza, siempre en búsqueda de crear cosas nuevas y compartir nuestras ideas. Durante varios años hemos visto a los mejores profesionistas y organizaciones en nuestra profesión tener éxito por un factor clave, su capacidad de aprender. Nuestro trabajo consiste en entender problemas complejos, diseñar y construir soluciones tecnológicas que permitan a nuestros clientes adaptarse, innovar y competir.

            </p>
            <p>
            Hace unos meses, empezamos a desarrollar Kidux, una plataforma que permitiera a los niños y padres explorar actividades extra curriculares de forma sencilla, seleccionando clases bajo demanda. 
            </p>
            <p>
            Empezamos a hacernos algunas preguntas, que han ido formando la visión de Kidux: 
¿Cómo podríamos facilitar que las familias descubran y experimenten, que los niños y niñas tengan la oportunidad de aprender cosas nuevas, de entender que les gusta y en su momento comprometerse a alguna actividad que los llene?
¿Cómo podríamos habilitar su curiosidad?
¿Qué podemos hacer para respetar y desarrollar su autonomía? 
¿Cómo habilitamos su capacidad de tener una vida plena y con propósito?

            </p>
            <p>
            En marzo creíamos que después de un par de meses podríamos regresar a la normalidad, que en el verano las clases de bici, fútbol, ballet, piano, volverían a ser parte de nuestro día a día. 

            </p>
            <p>
            Hoy creemos que es aún más importante pensar en cómo guiar a nuestros hijos e hijas a aprender, adaptarse, innovar, colaborar y competir sanamente. 
            </p>
            <p>Seguiremos moldeando nuestras ideas y nuestra solución, pero nos gustaría seguir avanzando en este camino con ustedes, estamos seguros será mucho más divertido.</p>
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  );
};

const style = {
  padding: "10px",
  fontSize: "2rem",
  lineHeight: "3rem",
  fontFamily: "Karma, Lato",
  textAlign: "center",
  fontWeight: "500" 
};

const titleStyle = {
  fontSize: "3rem",
  lineHeight: "3rem",
  fontFamily: "Lato, Open Sans",
}

const cardTextStyle = {
  paddingBottom: "16px"
}

const cardStyle = {
  border: "none"
}

export default Letter;
