import React from "react";
import { Container } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";

export default function KiduxContainer({ children }) {
  return (
    <Container fluid style={containerStyle}>
      <Header noLinks/>
      {children}
      <Footer />
    </Container>
  );
}

const containerStyle = {
  padding: 0
}
