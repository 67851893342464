import React from "react";
import { Button, Jumbotron, Container } from "react-bootstrap";

const Actions = () => {
  return (
    <Jumbotron fluid style={gradientBackground}>
      <Container className="d-flex justify-content-center">
        <Button
          variant="primary"
          size="lg"
          style={buttonStyle}
          onClick={redirectToMailchimp}
        >
          Ayudanos respondiendo la encuesta
        </Button>
      </Container>
    </Jumbotron>
  );
};

const redirectToMailchimp = () => {
  window.open(
    "https://us10.list-manage.com/survey?u=97fa4d6033c7bc447525839d7&id=2abdc0ebe6",
    "_blank"
  );
};

const buttonStyle = {
  backgroundColor: "#4ED0D1",
  borderColor: "#4ED0D1",
  margin: "0 16px",
  fontSize: "1.6rem",
};

const gradientBackground = {
  background: "#F8F8F8",
  textAlign: "center center",
  padding: "6rem 3rem",
};

export default Actions;
